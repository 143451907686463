// src/components/Result.js

import React from "react";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Result = () => {
  const navigate = useNavigate();

  const dummyData = {
    username: "John Doe",
    score: 4,
    totalQuestions: 5,
    questions: [
      {
        id: 1,
        question: "What is the capital of France?",
        answer: "Paris",
        correct: true,
      },
      { id: 2, question: "What is 2 + 2?", answer: "4", correct: true },
      {
        id: 3,
        question: "What is the color of the sky?",
        answer: "Blue",
        correct: true,
      },
      {
        id: 4,
        question: "What is the boiling point of water?",
        answer: "100°C",
        correct: true,
      },
      {
        id: 5,
        question: "Who wrote 'To Kill a Mockingbird'?",
        answer: "Harper Lee",
        correct: true,
      },
    ],
  };

  const handleBack = () => {
    navigate("/"); // Adjust the path according to your routing
  };

  return (
    <Container>
      <Row className="justify-content-center my-5">
        <Col md={8}>
          <Card>
            <Card.Header as="h3">Quiz Results</Card.Header>
            <Card.Body>
              <Card.Title>{dummyData.username}</Card.Title>
              <Card.Text>
                You scored {dummyData.score} out of {dummyData.totalQuestions}.
              </Card.Text>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Question</th>
                    <th>Your Answer</th>
                    <th>Correct</th>
                  </tr>
                </thead>
                <tbody>
                  {dummyData.questions.map((q) => (
                    <tr key={q.id}>
                      <td>{q.id}</td>
                      <td>{q.question}</td>
                      <td>{q.answer}</td>
                      <td>{q.correct ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button variant="primary" onClick={handleBack}>
                Back
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Result;
