import Cookies from "js-cookie";

const fetchHandler = async (parm) => {
  // const apiUrl = 'http://127.0.0.1:5000/api'+parm.endpoint;
  const apiUrl = "https://chs-api.dev.pearlax.com/api" + parm.endpoint;
  const _headers = {
    "Content-Type": "application/json",
  };
  if (Cookies.get("access_token")) {
    _headers["Authorization"] = "Bearer " + Cookies.get("access_token");
  }
  try {
    let _body = {
      method: parm.method,
      cache: "no-cache",
      credentials: "same-origin",
      headers: _headers,
      body: JSON.stringify(parm.data),
    };
    if (parm.method === "GET") {
      _body = {
        cache: "no-cache",
        credentials: "same-origin",
        headers: _headers,
      };
    }
    const response = await fetch(`${apiUrl}`, _body);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      sessionStorage.clear();
      window.location.assign("/login");
    } else {
      const data = await response.json();
      return { isError: true, severity: data.type, data: data.msg };
    }
  } catch (error) {
    return {
      isError: true,
      data: "We can't process your request at this time. Please try later.",
    };
  }
};

export default fetchHandler;
