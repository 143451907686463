import React from "react";
import DataTable from "react-data-table-component";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";

const mockExam = [
  {
    id: 100,
    title: "Dallas ",
    result: "57",
    examId: "10001",
    createdOn: "01-06-2024",
    endDate: "11-06-2024",
  },
  {
    id: 101,
    title: "Buyers Club",
    result: "30",
    examId: "10001",
    createdOn: "01-06-2024",
    endDate: "12-06-2024",
  },
  {
    id: 102,
    title: "Dallas Buyers Club",
    result: "89",
    examId: "10001",
    createdOn: "01-06-2024",
    endDate: "20-06-2024",
  },
  {
    id: 103,
    title: "Frida",
    examId: "10002",
    createdOn: "01-06-2024",
    endDate: "22-06-2024",
  },
  {
    id: 104,
    title: "Before Sunrise",
    examId: "10003",
    createdOn: "01-06-2024",
    endDate: "26-06-2024",
  },
  {
    id: 105,
    title: "The Rum Diary",
    examId: "10004",
    createdOn: "01-06-2024",
    endDate: "24-06-2024",
  },
];

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function handleTakeExam(examId) {
  if (examId) {
    localStorage.setItem("examId", examId);
  }
}
function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages; i++) {
    results.push(i);
  }

  return results;
}

const columns = [
  {
    name: "Exam Name",
    selector: (row) => row.title,
    sortable: true,
    width: "35%",
  },
  {
    name: "Created On",
    selector: (row) => row.createdOn,
    sortable: true,
    width: "15%",
  },
  {
    name: "Result",
    cell: (row) => {
      const progressBarClass =
        row.result < 50
          ? "bg-danger"
          : row.result < 75
          ? "bg-warning"
          : "bg-success";

      return (
        <div className="container">
          {row.result ? (
            <>
              <span className="float-left d-flex align-items-center justify-content-end mb-1">
                Attended on {row.endDate}
              </span>
              <div className="progress">
                <div
                  className={`progress-bar ${progressBarClass}`}
                  style={{ width: `${row.result}%` }}
                >
                  {row.result}
                </div>
              </div>
            </>
          ) : (
            `Expiring on ${row.endDate}`
          )}
        </div>
      );
    },
    width: "30%",
  },
  {
    name: "Action",
    button: true,
    cell: (row) => (
      <div className="container d-flex align-items-center justify-content-start">
        {row.result ? (
          <Link
            className="btn text-warning m-2 text-decoration-none fs-6"
            to="/result"
            style={{ "background-color": "transparent" }}
          >
            <i className="bi bi-journal-text"></i> View Result
          </Link>
        ) : (
          <Link
            className="btn text-success m-2 text-decoration-none fs-6"
            onClick={() => handleTakeExam(row.examId)}
            to="/quiz"
            style={{ "background-color": "transparent" }}
          >
            <i className="bi bi-pencil-square"></i> Take Exam
          </Link>
        )}
      </div>
    ),
    width: "15%",
  },
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav>
      <ul className="pagination d-flex justify-content-end align-items-center m-2">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check">
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
));

function Dashboard() {
  return (
    <div className="container mt-5">
      <div className="card shadow">
        <DataTable
          title="Exam List"
          columns={columns}
          data={mockExam}
          defaultSortFieldID={1}
          pagination
          paginationComponent={BootyPagination}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          className="fs-10"
          striped
          highlightOnHover
        />
      </div>
    </div>
  );
}

export default Dashboard;
