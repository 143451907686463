import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import the date picker library
import "react-datepicker/dist/react-datepicker.css"; // Import date picker CSS
import "./Login.css";
import { AuthendicationAPI } from "../api/Auth";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formatDate = (date) => {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return year + month + day;
    };

    const formattedDob = dob ? formatDate(dob) : ""; // Format the date to yyyyMMdd
    const response = await AuthendicationAPI.Login({
      username: email,
      password: formattedDob,
    });
    if (response.severity === "success") {
      navigate("/");
      setLoading(false);
    } else {
      setAlertMessage("Invalid email or date of birth");
      setShowAlert(true);
      setLoading(false);
    }
  };

  // Function to format the date for display in UI
  const formatDateDisplay = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };

  return (
    <div
      className="login-page d-flex align-items-center justify-content-center rounded"
      style={{
        backgroundImage: `url('/Background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <div className="container">
        <div className="bg-white shadow rounded">
          <div className="row">
            <div className="col-md-5 pe-0">
              <img
                src={"/Sideimage.png"}
                alt="Logo"
                className="side-image rounded d-none d-md-block"
              />
            </div>
            <div className="col-md-7 ps-0">
              <div className="form-left h-100 py-5 px-4">
                <div className="text-center mb-3">
                  <img
                    src="https://www.cardeahealthcare.in/images/icon.png"
                    width="250"
                    height="120"
                    alt="chs"
                  />
                </div>
                <h4 className="mb-4 mt-5" style={{ color: "#004177" }}>
                  Student Login
                </h4>
                {showAlert && (
                  <div className="alert alert-danger" role="alert">
                    {alertMessage}
                    <button
                      type="button"
                      className="float-end btn-close"
                      onClick={() => setShowAlert(false)}
                    ></button>
                  </div>
                )}
                <form onSubmit={handleSubmit} className="row g-4">
                  <div className="col-12">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-envelope-fill"></i>{" "}
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label>
                      Date of Birth<span className="text-danger">*</span>
                    </label>
                    <div className="input-group date-picker-group">
                      <div className="input-group-text">
                        <i className="bi bi-calendar"></i>
                      </div>
                      <DatePicker
                        className="form-control custom-date-input"
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="DD-MM-YYYY"
                        showYearDropdown
                        scrollableYearDropdown
                        customInput={
                          <input
                            type="text"
                            value={dob ? formatDateDisplay(dob) : ""}
                            readOnly
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className="col-sm-6"></div>

                  <div className="col-sm-6">
                    <button
                      type="submit"
                      className="btn px-4 pb-2 float-end text-white w-100"
                      disabled={loading}
                      style={{ backgroundColor: "#2eb85c" }}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Loading
                        </>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
