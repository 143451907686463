import fetchHandler from "./apiHandler";
import Cookies from "js-cookie";

export const AuthendicationAPI = {
  Login(data) {
    return fetchHandler({
      method: "POST",
      endpoint: "/student/login",
      data: data,
    }).then((response) => {
      if (response.isError) {
        return { severity: "error", content: response.data };
      } else {
        Cookies.set("access_token", response.access_token, { expires: 100 });
        Cookies.set("userrole", response.role, { expires: 100 });
        sessionStorage.setItem("access_token", response.name);
        sessionStorage.setItem("userrole", response.role);
        sessionStorage.setItem("username", response.name);
        return {
          severity: "success",
          name: response.name,
          role: response.role,
        }; // Return name and role
      }
    });
  },
  Logout() {
    Cookies.remove("access_token");
    Cookies.remove("userrole");
    sessionStorage.clear();
    window.location.assign("/login");
  },
};
